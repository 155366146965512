import times from 'utils/times.json'
import { DAYS_WEEK } from 'utils/constants'

const createInitialFormValues = () => {
  const defaultValues = {}

  DAYS_WEEK.forEach(({ name }, index) => {
    const dayName = name.toLowerCase()
    const dayChecked = index <= 4 ? true : false
    const dayTimeStart = index <= 4 ? times[32]?.time : false
    const dayTimeEnd = index <= 4 ? times[73]?.time : false
    defaultValues[dayName] = {}
    defaultValues[dayName].checked = dayChecked
    defaultValues[dayName].start = dayTimeStart
    defaultValues[dayName].end = dayTimeEnd
  })

  return defaultValues
}

export default createInitialFormValues
