import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { setIsPro, logIn } from '../../store/actions'
import { useGoogleLogin } from 'react-google-login'
import { authApi, profileApi } from 'api'

import { AuthRow } from 'components/ui/AuthRow'
import { AuthCol } from 'components/ui/AuthCol'
import { AuthHeader } from 'components/ui/AuthHeader'
import { AuthContent } from 'components/ui/AuthContent'
import { AuthItem } from 'components/ui/AuthItem'
import { Button } from 'components/ui/Button'
import { BaseLink } from 'components/ui/BaseLink'
import { WhyNeedAccount } from 'components/WhyNeedAccount'
import { InputLabel } from 'components/ui/InputLabel'
import { AlertMessage } from 'components/AlertMessage'
import FormInputWithLabel from 'components/Form/FormInputWithLabel/FormInputWithLabel'
import { FormCustomSelect } from 'components/Form'
import { professionalTypeOptions, SUBSCRIPTION_PLAN } from 'utils/constants'

const scope =
  'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly'
const clientId =
  '762923432718-9ifhiu7igag6c2902uh9nejfo28uuc69.apps.googleusercontent.com'

const signupSchema = Yup.object().shape({
  displayName: Yup.string()
    .required('Enter name, company or brand')
    .min(3, 'Name is too short')
    .max(80, 'Name is too long'),
  channelName: Yup.string()
    .required('Slug is required')
    .min(3, 'Slug is too short')
    .max(80, 'Slug is too long'),
  category: Yup.string()
    .required('Professional type is required')
    .oneOf(
      ['Therapist', 'Practitioner', 'Business', 'Technology', 'Other'],
      'Professional type is not selected'
    ),
})

const ProJoinPremiumPage = (props) => {
  const [success, setSuccess] = useState(false)
  const [channelName, setChannelName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [category, setCategory] = useState('')

  const history = useHistory()

  const onSuccess = async (res) => {
    const result = await authApi.googleLogInPro({
      access_token: res?.tokenObj?.access_token,
    })

    localStorage.setItem('token', result?.result?.access_token)
    localStorage.setItem('refreshToken', result?.result?.refresh_token)
    localStorage.setItem('isPro', true)

    props.setIsPro(true)

    await profileApi.setProInfo({
      channel_name: channelName,
      name: displayName,
      photo: res?.profileObj?.imageUrl,
      category,
      subscription_plan: SUBSCRIPTION_PLAN.premium,
    })

    await profileApi.getProfileInfo().then((res) => props.logIn(res?.result?.profile))

    history.push('/pro/rate', {
      channelName,
      category,
    })
  }

  useEffect(() => {
    document.title = 'TalkPro | Join'
  }, [])

  const onFailure = async (res) => {
    console.log('Error authenticating: ', res)
    setSuccess(false)
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    scope,
    accessType: 'offline',
    prompt: 'consent',
  })

  return (
    <AuthRow>
      <AuthCol type="gradient" />
      <AuthCol>
        <AuthHeader
          title="Welcome to TalkPro"
          subTitle="Please tell us about yourself and what type of pro you are."
          offsetMod
          dividerMod
        />
        {/* <AuthItem variant="divider">
          <AuthContent>
            You don’t need to remember an email and password, <br /> plus we’ll send you a
            reminders before your talk
          </AuthContent>
        </AuthItem> */}
        <AuthItem variant="divider">
          <Formik
            initialValues={{
              displayName: '',
              channelName: '',
              category: 'Professional Type',
              agreement: false,
            }}
            validationSchema={signupSchema}
            onSubmit={async (values, { setSubmitting }) => {
              signIn()
              setSuccess(true)
              setSubmitting(false)
            }}
          >
            {({
              errors,
              submitForm,
              values,
              setFieldValue,
              isSubmitting,
              handleChange,
            }) => (
              <Form>
                <AuthItem variant="divider">
                  <div className="form_group__field_wrapper">
                    <FormInputWithLabel
                      colorV2
                      type="text"
                      label="Enter name, company or brand"
                    >
                      <Field
                        className="form_group__field form_group__field--suffix_mod"
                        name="displayName"
                        id="displayName"
                        type="text"
                        placeholder=""
                        disabled={isSubmitting || success}
                        onChange={({ target: { value } }) =>
                          setFieldValue('displayName', value) && setDisplayName(value)
                        }
                      />
                      <ErrorMessage name="displayName">
                        {(msg) => <AlertMessage message={msg} inputMod />}
                      </ErrorMessage>
                    </FormInputWithLabel>
                  </div>
                </AuthItem>
                <AuthItem variant="divider">
                  <AuthContent>
                    Create your TalkPro URL describing you or your business
                  </AuthContent>
                  <div className="form_group__field_wrapper">
                    <InputLabel variant="preffix">{`talkpro.com/`}</InputLabel>
                    <div class="form_group__field_block">
                      <FormInputWithLabel colorV2 type="text" label="Enter slug">
                        <Field
                          className="form_group__field form_group__field--suffix_mod"
                          name="channelName"
                          id="channelName"
                          type="text"
                          placeholder=""
                          disabled={isSubmitting || success}
                          onChange={({ target: { value } }) =>
                            /^[a-z0-9]{0,80}$/i.test(value) &&
                            setFieldValue('channelName', value.toLowerCase()) &&
                            setChannelName(value.toLowerCase())
                          }
                        />
                        <ErrorMessage name="channelName">
                          {(msg) => <AlertMessage message={msg} inputMod />}
                        </ErrorMessage>
                      </FormInputWithLabel>
                    </div>
                  </div>
                </AuthItem>
                <AuthItem>
                  {/* <Field
                    name="category"
                    id="category"
                    as="select"
                    className="form_group__field"
                    disabled={isSubmitting || success}
                    onChange={({ target: { value } }) => {
                      setFieldValue('category', value) && setCategory(value)
                    }}
                  >
                    <option value="Professional Type" disabled hidden>
                      Select Professional Type
                    </option>
                    <option value="Therapist">Therapist</option>
                    <option value="Practitioner">Practitioner</option>
                    <option value="Business">Business</option>
                    <option value="Technology">Technology</option>
                    <option value="Other">Other</option>
                  </Field> */}
                  <div class="form_group__field_block">
                    <FormCustomSelect
                      v3
                      placeholder="Select Professional Type"
                      options={professionalTypeOptions}
                      disabled={isSubmitting || success}
                      onChange={(e) => {
                        setFieldValue('category', e.value.name) &&
                          setCategory(e.value.name)
                      }}
                    />
                    <ErrorMessage name="category">
                      {(msg) => <AlertMessage message={msg} inputMod />}
                    </ErrorMessage>
                  </div>
                </AuthItem>
                <AuthItem>
                  <Button
                    type="submit"
                    variant="default"
                    iconName="google"
                    fullWidth
                    disabled={isSubmitting || success}
                    onClick={(e) => {
                      e.preventDefault()
                      submitForm()
                    }}
                  >
                    Continue with Google
                  </Button>
                </AuthItem>
                <AuthItem>
                  <BaseLink
                    href="/pro/login"
                    onClick={(e) => {
                      e.preventDefault()
                      history.push('/pro/login', { login: true })
                    }}
                  >
                    Already have an account?
                  </BaseLink>
                </AuthItem>
              </Form>
            )}
          </Formik>
        </AuthItem>
        <AuthItem variant="divider">
          <WhyNeedAccount />
        </AuthItem>
      </AuthCol>
    </AuthRow>
  )
}

const mapStateToProps = ({ auth: { error, attributes } }) => ({
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setIsPro,
  logIn,
}

export default connect(mapStateToProps, willMapDispatchToProps)(ProJoinPremiumPage)
