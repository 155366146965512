import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { subDays, isBefore, isFuture } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import { ProfileSideBar } from './ProfileSideBar'
import { TimePicker } from 'components/TimePicker'
import { ProfileTabs } from 'components/ProfileTabs'
import { Spinner } from 'components/Spinner'
import { FormCustomSelect } from 'components/Form'

import { calendarApi, eventApi, profileApi } from 'api'

import { timezoneOptions } from 'utils/constants'

import { setPro } from 'store/actions'

const hasCheckedDays = (data) => {
  if (!data.one_on_one_service) return false

  const service =
    typeof data.one_on_one_service === 'string'
      ? JSON.parse(data.one_on_one_service)
      : data.one_on_one_service

  return Object.values(service).some((day) => {
    return day.checked
  })
}

const BookPage = (props) => {
  const [timePickerData, setTimePickerData] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const history = useHistory()
  const [disabledDates, setDisabledDates] = useState([])
  const [availableDates, setAvailableDates] = useState([])
  const [pro, setPro] = useState(null)
  const [isShowCalendar, setShowCalendar] = useState(false)
  const [events, setEvents] = useState([])
  const [userVideos, setUserVideos] = useState(null)
  const [mobileClicked, setMobileClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [timezone, setTimezone] = useState({ name: 'America/New_York', tag: 'en-US' })
  const [hasActiveDays, setHasActiveDays] = useState(false)

  const [calendarValue, setCalendarValue] = useState(
    new Date(formatInTimeZone(new Date(), timezone.name, 'MM/dd/yyyy HH:mm XXXX'))
  )

  const handleShowCalendar = () => {
    setShowCalendar(true)
  }

  let [called, setCalled] = useState(3)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    const handleApi = async () => {
      props.setIsLoading(true)

      const channelName = window.location.pathname.split('/')[1]
      const proInfo = await profileApi.getProInfo({ pro: channelName })
      const proEvents = await eventApi.proEventList({ pro: channelName })

      if (!proInfo?.result?.id) return history.push('/')
      else {
        document.title = `TalkPro | Book a call with ${proInfo?.result?.user?.name}`
        setPro(proInfo.result)
        props.setPro(proInfo.result)
        setAvailableDates(JSON.parse(proInfo.result.one_on_one_service))
      }

      if (!proEvents.error)
        setEvents(
          proEvents?.result
            .filter((e) => isFuture(new Date(e.scheduled_at)) && e.title && !e.private)
            .sort((a, b) => new Date(a?.scheduled_at) - new Date(b?.scheduled_at))
        )

      setIsLoading(false)
      props.setIsLoading(false)

      const busyDays = await calendarApi.getBusyDays(proInfo?.result?.id)
      const dates = busyDays?.result?.pro?.calendars?.primary?.busy?.map((e) => {
        let formatedDates = {
          start: new Date(e.start),
          end: new Date(e.end),
        }
        return formatedDates
      })

      if (dates?.length > 0) setDisabledDates(dates)
    }

    if (pro?.strikes >= 3) {
      setDisabled(new Date())
    }
    handleApi()
    window.addEventListener('resize', handleResize)
  }, [pro?.strikes])

  useEffect(() => {
    props.setIsBookPage(true)
    return () => {
      props.setIsBookPage(false)
    }
  }, [])

  useEffect(() => {
    if (windowWidth >= 1024 && isShowCalendar) {
      setShowCalendar(false)
    }
  }, [windowWidth])

  useEffect(() => {
    let videoUrls = []
    if (pro?.video_urls) {
      videoUrls = JSON.parse(pro?.video_urls)
    } else if (props?.user?.video_urls) {
      videoUrls = JSON.parse(props?.user?.video_urls)
    }
    setUserVideos(videoUrls)
  }, [pro, props?.user])

  if (called > 0 && pro !== undefined) {
    setCalled(0)
  }

  useEffect(() => {
    if (props?.user) {
      setHasActiveDays(hasCheckedDays(props?.user))
    }
  }, [props])

  return (
    <section className="section profile book_page">
      {hasActiveDays === true ? (
        !isLoading ? (
          <>
            <div className="book__cols book__cols--lg_mod">
              <div className="book__col book__col--sidebar">
                <ProfileSideBar profile={pro} windowWidth={windowWidth} />
              </div>
              {(windowWidth >= 1024 ||
                (windowWidth < 1024 &&
                  events.length === 0 &&
                  !(userVideos && userVideos.length)) ||
                mobileClicked ||
                isShowCalendar) && (
                <div className="book__col book__col--main">
                  <div className="profile_calendar">
                    <div className="profile_calendar__block">
                      <div className="profile_calendar__title">Pick a day</div>
                      <Calendar
                        onChange={(e) => {
                          setTimePickerData({
                            calendarValue: e,
                            pro,
                            user: props.user,
                            disabledDates: disabledDates?.filter(
                              (e) => e.start.getDate() === calendarValue.getDate()
                            ),
                          })
                          setCalendarValue(
                            new Date(
                              formatInTimeZone(e, timezone.name, 'MM/dd/yyyy HH:mm XXXX')
                            )
                          )
                        }}
                        value={calendarValue}
                        calendarType="US"
                        maxDate={disabled}
                        showNeighboringMonth={false}
                        tileDisabled={({ date, view }) =>
                          (view === 'month' &&
                            !availableDates[
                              date
                                .toLocaleDateString('en-us', {
                                  weekday: 'long',
                                  // timeZone: timezone.name,
                                })
                                .toLowerCase()
                            ].checked) ||
                          isBefore(date, subDays(new Date(), 1)) ||
                          disabled
                        }
                        tileClassName={({ date, view }) =>
                          view === 'month' && date.getDay() > 0 && date.getDay() < 6
                            ? 'border_mod'
                            : ''
                        }
                      />
                    </div>

                    <div className="profile_calendar__select">
                      <FormCustomSelect
                        options={timezoneOptions}
                        onChange={(e) => {
                          setTimezone(e.value)
                          setCalendarValue(
                            new Date(
                              formatInTimeZone(
                                calendarValue,
                                timezone.name,
                                'MM/dd/yyyy HH:mm XXXX'
                              )
                            )
                          )
                        }}
                      />
                    </div>

                    {/* {pro?.expectations && (
                      <div className="profile_calendar__info">
                        <div className="profile_calendar__info_title">What to expect</div>
                        <div className="profile_calendar__info_list">
                          <div className="profile_calendar__info_item">
                            {pro?.expectations}
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              )}
              <div className="book__col book__col--aside">
                {!timePickerData && !isShowCalendar && (
                  <>
                    <ProfileTabs user={props?.user} pro={pro} events={events} />
                    {!isShowCalendar && (
                      <div className="book_mobile_btn">
                        <button
                          type="button"
                          className="btn_v9"
                          onClick={handleShowCalendar}
                        >
                          Book Session
                        </button>
                      </div>
                    )}
                  </>
                )}
                {events.length === 0 && !(userVideos && userVideos.length) && (
                  <div className="book__aside_content">
                    <TimePicker
                      newDate={calendarValue}
                      timezone={timezone}
                      pro={pro}
                      user={props?.user}
                      availableDates={availableDates}
                      disabledDates={disabledDates?.filter(
                        (e) => e.start.getDate() === calendarValue.getDate()
                      )}
                    />
                  </div>
                )}
                {timePickerData && (
                  <div className="book__aside_content book__aside_content--mobile_popup_mod">
                    <TimePicker
                      newDate={calendarValue}
                      timezone={timezone}
                      pro={pro}
                      user={props?.user}
                      availableDates={availableDates}
                      disabledDates={disabledDates?.filter(
                        (e) => e.start.getDate() === calendarValue.getDate()
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            {windowWidth < 1024 &&
              events.length > 0 &&
              !mobileClicked &&
              !timePickerData && (
                <div className="profile_bottom">
                  <button
                    className="profile_bottom__title"
                    onClick={(e) => {
                      e.preventDefault()
                      setMobileClicked(true)
                    }}
                  >
                    Pick a day
                  </button>
                </div>
              )}
          </>
        ) : (
          <Spinner className="form_group__button book_mod" />
        )
      ) : null}
    </section>
  )
}

const mapStateToProps = ({ auth: { user, error, attributes } }) => ({
  user,
  authError: error,
  attributes,
})

const willMapDispatchToProps = {
  setPro,
}

export default connect(mapStateToProps, willMapDispatchToProps)(BookPage)
