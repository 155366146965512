import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ErrorMessage, Formik, Field } from 'formik'

import { AvailableDay } from './ui'

import styles from './AvailabilityForm.module.scss'
import { AlertMessage } from 'components/AlertMessage'
import { oneOnOneServiceName, PROMISE_STATES, SUBSCRIPTION_PLAN } from 'utils/constants'
import { createInitialFormValues } from './helpers'
import { paymentApi, profileApi } from 'api'
import { timezoneOptions } from 'utils/constants'
import times from 'utils/times.json'
import { FormCustomSelect } from 'components/Form'
import PaymentsImg from 'i/accept_payments.png'
import { connect } from 'react-redux'

const validate = (values, props) => {
  const errors = {}

  if (props.subscriptionPlan === SUBSCRIPTION_PLAN.premium) {
    if (!values.rate) {
      errors.rate = 'Rate is required'
    } else if (values.rate <= 0) {
      errors.rate = 'Rate must be greater than zero'
    }
  }

  return errors
}

const removeRate = (obj) => {
  const { rate, ...rest } = obj
  return rest
}

const AvailabilityForm = ({
  initialValues,
  setFieldValue,
  subscriptionPlan,
  onSubmitCallback,
  setTimezone,
  user,
}) => {
  const [status, setStatus] = useState(PROMISE_STATES.idle)
  const [errorSubmitMessage, setErrorSubmitMessage] = useState(null)
  const [rate, setRate] = useState(user?.rate)
  const [formInitialValues, setFormInitialValues] = useState()
  const [isRatePage, setIsRatePage] = useState(window.location.pathname === '/pro/rate')
  const history = useHistory()

  useEffect(() => {
    const values = initialValues
      ? { ...JSON.parse(initialValues) }
      : { ...createInitialFormValues() }

    if (subscriptionPlan === SUBSCRIPTION_PLAN.premium) {
      values.rate = rate
    }

    setFormInitialValues(values)
  }, [initialValues, rate, subscriptionPlan])

  useEffect(() => {
    console.log('-----')
    console.log(formInitialValues)
  }, [formInitialValues])

  return (
    <>
      {formInitialValues && (
        <Formik
          initialValues={formInitialValues}
          validate={(values) => validate(values, { subscriptionPlan })}
          onSubmit={async (values) => {
            let formData = { ...values }
            let updatedRate = rate !== values.rate ? values.rate : rate

            const defaultStart = times[33]?.time
            const defaultEnd = times[73]?.time

            Object.values(formData).forEach((value) => {
              if (value.checked === false) {
                value.start = defaultStart
                value.end = defaultEnd
              }
            })

            if (subscriptionPlan === SUBSCRIPTION_PLAN.premium) {
              formData = removeRate(values)
            }

            if (setFieldValue && typeof setFieldValue === 'function') {
              setFieldValue(oneOnOneServiceName, formData)
              onSubmitCallback()
              setFieldValue('rate', updatedRate)
              return
            }
            setStatus(PROMISE_STATES.pending)

            try {
              await profileApi.setProInfo({
                [oneOnOneServiceName]: JSON.stringify(formData),
                rate: updatedRate,
              })
              setStatus(PROMISE_STATES.fulfilled)
              if (parseInt(updatedRate) === 0 && !user?.subscription_timestamp) {
                history.push('/pro/stripe', updatedRate)
              }
              if (user?.rate < parseInt(updatedRate) && !user?.stripe_info) {
                paymentApi
                  .connectStripe()
                  .then((res) => (window.location = res.result.action_url))
              }
              onSubmitCallback()
            } catch (error) {
              setStatus(PROMISE_STATES.rejected)
              setErrorSubmitMessage(error.message)
            }
          }}
        >
          {({ values, handleSubmit, handleChange, submitForm, errors }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.container}>
                <div className="form_group form_group--offset_v3_mod profile_mod">
                  <FormCustomSelect
                    v2
                    placeholder="Select time zone"
                    options={timezoneOptions}
                    onChange={(e) => {
                      setTimezone(e.value)
                    }}
                  />
                </div>
                <ul className={styles.list}>
                  {Object.entries(formInitialValues).map(([day, data]) => {
                    if (day === 'rate') {
                      return null
                    }

                    return (
                      <li className={styles.item} key={day}>
                        <AvailableDay
                          name={day}
                          checked={data.checked}
                          timeFrom={data.start}
                          timeTo={data.end}
                          dayNameFormProperty={day}
                          handleChange={handleChange}
                        />
                      </li>
                    )
                  })}
                </ul>
                {subscriptionPlan === SUBSCRIPTION_PLAN.premium ? (
                  <div className="form_group form_group--border_mod profile_mod">
                    <label className="form_group__label form_group__label--v2_mod">
                      <strong>Do you charge for your time?</strong>
                      If yes, you will be re-directed to enter banking details to receive
                      payments.
                    </label>
                    <div className="form_row form_row--session_mod">
                      <div className="form_group form_group--session_mod">
                        <span className="form_group__preffix">$</span>
                        <Field
                          className="form_group__field"
                          name="rate"
                          type="number"
                          placeholder={user?.rate ? user?.rate : `Enter amount`}
                          onChange={(e) => {
                            setRate(e.target.value)
                            handleChange(e)
                          }}
                        />
                        <span className="form_group__suffix session_mod">/ Meeting</span>
                      </div>
                      <div className="form_payments">
                        <div className="form_payments__title">Accept payments:</div>
                        <div className="form_payments__pic">
                          <img src={PaymentsImg} alt="" className="form_payments__img" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <ErrorMessage name="rate">
                {(msg) => <AlertMessage message={msg} formMod />}
              </ErrorMessage>
              {errorSubmitMessage && (
                <AlertMessage message={errorSubmitMessage} formMod />
              )}
              <button
                type="submit"
                className="form_group__button"
                disabled={status === PROMISE_STATES.pending}
                onClick={(e) => (e.preventDefault(), submitForm())}
              >
                {isRatePage ? 'Continue' : 'Save schedule'}
              </button>
            </form>
          )}
        </Formik>
      )}
    </>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
})

export default connect(mapStateToProps)(AvailabilityForm)
